import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import {createBrowserHistory} from 'history';

//import { observer } from 'mobx-react';
import React, {Suspense, lazy, Fragment, Component} from 'react';
import Loader from 'react-loaders';
import Auth from '../../services/Auth';
import {ToastContainer} from 'react-toastify';

const Login = lazy(() => import('../../MainPages/UserPages/Login'));
const Dashboards = lazy(() => import('../../MainPages/Dashboards'));

const Callback = lazy(() => import('../../MainPages/Components/Callback'));
const auth = new Auth();

const browserHistory = createBrowserHistory();

class AppMain extends Component {
  handleAuthentication({location}) {
    // console.log(location);
    //console.log(auth);
    if (/access_token|id_token|error/.test(location.hash)) {
      //console.log(location.hash);
      // auth.handleAuthentication(location.hash);
      auth.handleAuthentication();
    }
  }

  login() {
    auth.login();
  }

  render() {
    const {isAuthenticated} = auth;

    const loader = (
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-pulse-rise" active/>
          </div>
          <h6 className="mt-5">Loading, please wait.</h6>
        </div>
      </div>
    );

    let routes = [
      <Route
        path="/callback"
        render={props => {
          this.handleAuthentication(props);
          return <Callback {...props} />;
        }}
      />
    ];

    if (isAuthenticated()) {
      routes = [
        ...routes,
        <Route
          exact
          path="/"
          component={() =>
            <Redirect to={`/dashboards/analytics`}/>
          }
        />,
        <Route
          exact
          path="/login"
          component={() =>
            <Redirect to={`/dashboards/analytics`}/>
          }
        />,
        <Route path="/dashboards" auth={auth} component={Dashboards}/>
      ];
    } else {
      routes = [
        ...routes,
        <Route
          exact
          path="/"
          component={() =>
            <Redirect to={`/login`}/>
          }
        />,
        <Route
          exact
          path="/login"
          component={() =>
            <Login auth={auth} {...this.props} />
          }
        />,
        <Route path="/dashboards" component={() =>
          <Redirect to={`/login`}/>
        }/>
      ];
    }

    return (
      <Router history={browserHistory}>
        <Fragment>
          <Suspense
            fallback={loader}
          >

            <Switch>

              {routes}

            </Switch>

          </Suspense>

          <ToastContainer/>

        </Fragment>
      </Router>
    );
  }
}

export default AppMain;
